$(document).ready(function() {
  // get announcement bar height and add to body margin
  if ($(".announcement").length) {
    let announcement = document.querySelector('.announcement');
    let announcementWidth = announcement.offsetWidth;
    let announcementHeight = announcement.offsetHeight;
    if (announcementHeight > 0) {
    //   $('header').css('margin-top', announcementHeight);
      $('body').css('padding-top', announcementHeight);
      $('.portal-nav__top').css('padding-top', announcementHeight);
      // $('html').css('padding-top', announcementHeight);
    }
  }

    // scroll
    $(window).on("scroll", function() {
        if ($(this).scrollTop() > 50) {
          if (!$("body").hasClass("body--scrolled")) {
            $("body").addClass("body--scrolled");
            $(".navbar").addClass("navbar--scrolled");
            $(".portal-nav").addClass("portal-nav--scrolled");
            $(".announcement").slideUp(500);
          }
        } else {
          if ($("body").hasClass("body--scrolled")) {
            $("body").removeClass("body--scrolled");
            $(".navbar").removeClass("navbar--scrolled");
            $(".portal-nav").removeClass("portal-nav--scrolled");
            $(".announcement").slideDown(500);
          }
        }
      });
});
