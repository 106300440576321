import "bootstrap";
import Tooltip from "bootstrap/js/dist/tooltip";

document.addEventListener("DOMContentLoaded", function () {
  // enable bootstrap tooltips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
  );

  // also make sure tooltips have help cursor
//   tooltipTriggerList.forEach((tooltipTriggerEl) => {
//     tooltipTriggerEl.style.cursor = "help";
//   });
});
